import { useEffect } from 'react';

const useHideScroll = (isHideScroll?: boolean) => {
  useEffect(() => {
    if (!isHideScroll) return;
    document.body.style.overflow = 'hidden';

    return () => {
      document.body.style.overflow = '';
    };
  }, [isHideScroll]);

  return null;
};

export default useHideScroll;
