import React, { useState } from 'react';
import styles from './styles.module.css';
import { TeamCard } from '@entities/team-card';
import { PointOurTeamItem, PointsOurTeamProps } from '../model/types';
import ColorTextParser from '@shared/lib/text-parser';
import { ArrowRight } from '@shared/icons/arrow-right';
import { Modal } from '@shared/modal';
import OurTeamModal from './our-team-modal';
import { Button } from '@shared/ui/button';

const MAIN_TEXT_COLOR = '#A0A0A0';
const ACCENT_TEXT_COLOR = '#fff';

export const SectionOurTeam: React.FC<PointsOurTeamProps> = ({
  title,
  subtitle,
  pointOurTeamContent,
  buttonText,
  onApplyClick,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedCard, setSelectedCard] = useState<PointOurTeamItem | null>(null);

  const handleOpenModal = (card: PointOurTeamItem) => {
    setSelectedCard(card);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedCard(null);
  };

  return (
    <div className={styles.teamWrapper}>
      <div className={styles.textWrapper}>
        <h2 className={styles.headerText}>
          <ColorTextParser text={title} mainColor={MAIN_TEXT_COLOR} secondColor={ACCENT_TEXT_COLOR} />
        </h2>
        <p className={styles.text}>{subtitle}</p>
      </div>
      <div className={styles.cardsSectionWrapper}>
        {pointOurTeamContent.map((item, index) => (
          <TeamCard
            key={index}
            Icon={item.Icon}
            cardName={item.cardName}
            description={item.description}
            onClick={() => handleOpenModal(item)}
          />
        ))}

        <div className={styles.button}>
          <Button title={buttonText} icon={<ArrowRight />} onClick={() => handleOpenModal(pointOurTeamContent[0])} />
        </div>
      </div>
      <Modal isOpen={isModalOpen} onClose={handleCloseModal}>
        <OurTeamModal
          isOpen={isModalOpen}
          closeModalHandler={handleCloseModal}
          selectedCard={selectedCard}
          onApplyClick={onApplyClick}
        />
      </Modal>
    </div>
  );
};
