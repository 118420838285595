import styles from './light-angle.module.css';
import cn from 'classnames';

interface LightAngleProps {
  className?: string;
}

export const LightAngle: React.FC<LightAngleProps> = ({ className = '' }) => {
  return (
    <svg
      className={cn(styles.container, className)}
      width="1440"
      height="2500"
      viewBox="0 0 1440 2500"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_f_1432_704)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M540 1700C1037.06 1700 1440 1297.06 1440 800V1700H540Z"
          fill="#FF8A00"
          fillOpacity="0.6"
        />
      </g>
      <g filter="url(#filter1_f_1432_704)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M540 1700C1037.06 1700 1440 1297.06 1440 800V1700H540Z"
          fill="#FF8A00"
          fillOpacity="0.6"
        />
      </g>
      <g filter="url(#filter2_f_1432_704)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M751.047 1700C1131.31 1700 1439.61 1391.93 1440 1011.76V1700H751.047C751.047 1700 751.047 1700 751.047 1700Z"
          fill="#FF7A00"
          fillOpacity="0.4"
        />
      </g>
      <g filter="url(#filter3_f_1432_704)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M751.047 1700C1131.31 1700 1439.61 1391.93 1440 1011.76V1700H751.047C751.047 1700 751.047 1700 751.047 1700Z"
          fill="#FF7A00"
          fillOpacity="0.4"
        />
      </g>
      <g filter="url(#filter4_f_1432_704)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M964.258 1700C1227.04 1699.77 1440 1486.67 1440 1223.84C1440 1223.84 1440 1223.84 1440 1223.84H1440V1700H964.258Z"
          fill="#FB8027"
          fillOpacity="0.2"
        />
      </g>
      <g filter="url(#filter5_f_1432_704)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M964.26 1700C1227.04 1699.77 1440 1486.67 1440 1223.84C1440 1223.84 1440 1223.84 1440 1223.84H1440V1700H964.26Z"
          fill="#FB8027"
          fillOpacity="0.2"
        />
      </g>
      <g filter="url(#filter6_f_1432_704)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1108 1700.28C1291.13 1700.28 1439.62 1551.93 1439.84 1368.85V1700.28H1108V1700.28C1108 1700.28 1108 1700.28 1108 1700.28Z"
          fill="#FBB327"
        />
      </g>
      <g filter="url(#filter7_f_1432_704)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1108 1700.28C1291.13 1700.28 1439.62 1551.93 1439.84 1368.85V1700.28H1108V1700.28C1108 1700.28 1108 1700.28 1108 1700.28Z"
          fill="#FBB327"
        />
      </g>
      <g filter="url(#filter8_f_1432_704)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1238 1699.62C1349.35 1699.62 1439.62 1609.35 1439.62 1498L1439.62 1498V1699.62H1238Z"
          fill="#FEC455"
        />
      </g>
      <g filter="url(#filter9_f_1432_704)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1238 1699.62C1349.35 1699.62 1439.62 1609.35 1439.62 1498L1439.62 1498V1699.62H1238Z"
          fill="#FEC455"
        />
      </g>
      <defs>
        <filter
          id="filter0_f_1432_704"
          x="-260"
          y="0"
          width="2500"
          height="2500"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="400" result="effect1_foregroundBlur_1432_704" />
        </filter>
        <filter
          id="filter1_f_1432_704"
          x="-260"
          y="0"
          width="2500"
          height="2500"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="400" result="effect1_foregroundBlur_1432_704" />
        </filter>
        <filter
          id="filter2_f_1432_704"
          x="351.047"
          y="611.764"
          width="1488.95"
          height="1488.24"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="200" result="effect1_foregroundBlur_1432_704" />
        </filter>
        <filter
          id="filter3_f_1432_704"
          x="351.047"
          y="611.764"
          width="1488.95"
          height="1488.24"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="200" result="effect1_foregroundBlur_1432_704" />
        </filter>
        <filter
          id="filter4_f_1432_704"
          x="764.258"
          y="1023.84"
          width="875.74"
          height="876.163"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="100" result="effect1_foregroundBlur_1432_704" />
        </filter>
        <filter
          id="filter5_f_1432_704"
          x="764.26"
          y="1023.84"
          width="875.74"
          height="876.163"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="100" result="effect1_foregroundBlur_1432_704" />
        </filter>
        <filter
          id="filter6_f_1432_704"
          x="948"
          y="1208.85"
          width="651.838"
          height="651.431"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="80" result="effect1_foregroundBlur_1432_704" />
        </filter>
        <filter
          id="filter7_f_1432_704"
          x="948"
          y="1208.85"
          width="651.838"
          height="651.431"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="80" result="effect1_foregroundBlur_1432_704" />
        </filter>
        <filter
          id="filter8_f_1432_704"
          x="1158"
          y="1418"
          width="361.619"
          height="361.619"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="40" result="effect1_foregroundBlur_1432_704" />
        </filter>
        <filter
          id="filter9_f_1432_704"
          x="1158"
          y="1418"
          width="361.619"
          height="361.619"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="40" result="effect1_foregroundBlur_1432_704" />
        </filter>
      </defs>
    </svg>
  );
};
