import img1 from '@shared/assets/images/choose-us-screen/career-01.png';
import img2 from '@shared/assets/images/choose-us-screen/career-02.png';
import img3 from '@shared/assets/images/choose-us-screen/career-03.png';
import img4 from '@shared/assets/images/choose-us-screen/career-04.png';

export const CHOOSE_US_H2_TITLE = 'Почему **стоит выбрать нас**';
export const CHOOSE_US_SCREEN_MOCK_CARDS = [
  {
    title: 'Зарплата **выше рынка**',
    description: 'Чтобы привлечь лучших специалистов в нашу команду, мы готовы платить им больше',
    image: img1,
  },
  {
    title: '**Гибкий** формат работы',
    description: 'С нами можно работать из офисе, в гибридном формате или удалённо.',
    image: img2,
  },
  {
    title: 'Корпоративные **мероприятия**',
    description:
      'Мы организуем спортивные олимпиады и турниры, а также праздничные события несколько раз в год, позволяя вам не только трудиться, но и весело проводить время с коллегами.',
    image: img3,
  },
  {
    title: 'Отличный **социальный пакет**',
    description:
      'Вам доступны такие преимущества, как ДМС, бесплатные обеды и полдники, мобильная связь, а также скидки и бонусы от наших партнеров.',
    image: img4,
  },
];