import { useLayoutEffect, useRef } from 'react';
import H2Heading from '@shared/ui/h2-heading';
import ChooseUsCard from '@features/choose-us-screen/ui/choose-us-card/choose-us-card.tsx';
import { IChooseUsScreenProps } from '@features/choose-us-screen/model/types.ts';

import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);

import styles from '@features/choose-us-screen/ui/choose-us.module.css';

import { CHOOSE_US_H2_TITLE, CHOOSE_US_SCREEN_MOCK_CARDS } from '@features/choose-us-screen/api/choose-us-mock-data.ts';

const ChooseUsScreen = ({ mainTextColor, accentTextColor }: IChooseUsScreenProps) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const cardsRef = useRef<HTMLDivElement[]>([]);

  useLayoutEffect(() => {
    const ctx = gsap.context(() => {
      ScrollTrigger.normalizeScroll({ allowNestedScroll: true });

      const lastCardST = ScrollTrigger.create({
        trigger: cardsRef.current[cardsRef.current.length - 1],
        start: 'top 10%',
      });

      cardsRef.current.forEach((card) => {
        ScrollTrigger.create({
          trigger: card,
          start: 'top 10%',
          end: () => lastCardST.start,
          pin: true,
          pinSpacing: false,
        });
      });
    });
    return () => ctx.revert();
  }, []);

  const addCardRef = (el: HTMLDivElement | null) => {
    if (el && !cardsRef.current.includes(el)) {
      cardsRef.current.push(el);
    }
  };

  return (
    <div className={styles.container} ref={containerRef}>
      <H2Heading heading={CHOOSE_US_H2_TITLE} mainTextColor={mainTextColor} accentTextColor={accentTextColor} />
      <section className={styles.pinnedSection}>
        <div className={styles.cardsWrapper}>
          {CHOOSE_US_SCREEN_MOCK_CARDS.map((card, index, array) => (
            <ChooseUsCard
              ref={addCardRef}
              key={index}
              cardInfo={card}
              currentIndex={index + 1}
              allCardsCount={array.length}
              mainTextColor={mainTextColor}
              accentTextColor={accentTextColor}
            />
          ))}
        </div>
      </section>
    </div>
  );
};

export default ChooseUsScreen;
