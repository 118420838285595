import { useRef } from 'react';
import cn from 'classnames';
import useClickOutside from '@shared/hooks/useClickOutside/useClickOutside.ts';
import CustomCursor from '@features/start-screen/ui/custom-cursor/custom-cursor.tsx';
import H2Heading from '@shared/ui/h2-heading';
import { Close } from '@shared/ui/icons/Close.tsx';
import ArrowLeftIcon from '@shared/icons/our-products/arrow-left-icon.tsx';
import OurProductModalCard from '@features/our-products-screen/ui/our-products-modal/our-product-modal-card/our-product-modal-card.tsx';
import { IOurProducts } from '@features/our-products-screen/api/our-products-mock-data.tsx';

import styles from './styles.module.css';
import { ACCENT_TEXT_COLOR, MAIN_TEXT_COLOR } from '@features/start-screen/lib/start-screen-mock-data.ts';

const OurProductsModal = ({
  closeModalHandler,
  currentProjects,
  isOpen,
}: {
  closeModalHandler: () => void;
  currentProjects: IOurProducts[];
  isOpen?: boolean;
}) => {
  const modalRef = useRef<HTMLDivElement | null>(null);
  const contentRef = useRef(null);
  useClickOutside(contentRef, closeModalHandler);

  return (
    <div className={cn(styles.modalContainer, isOpen ? styles.isDarken : '')}>
      <div className={styles.innerContainer}>
        <div className={cn(styles.emptySpace)} ref={modalRef}>
          <CustomCursor containerRef={modalRef} className={styles.customCursorPaddings}>
            <Close />
          </CustomCursor>
        </div>
        <div className={cn(styles.modalLinkContentWrapper, isOpen ? styles.visible : '')} ref={contentRef}>
          <div className={styles.headingContainer}>
            <button className={styles.iconWrapper} onClick={closeModalHandler}>
              <ArrowLeftIcon />
            </button>
            <H2Heading
              heading="Цифровизация **корпоративных систем**"
              accentTextColor={ACCENT_TEXT_COLOR}
              mainTextColor={MAIN_TEXT_COLOR}
            />
            <div className={styles.spaceFiller} />
          </div>
          <ul className={styles.list}>
            {currentProjects?.map((el, index) => (
              <OurProductModalCard key={index} num={index + 1} title={el.title} description={el.description} />
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default OurProductsModal;
