import { useState } from 'react';
import cn from 'classnames';
import { Modal } from '@shared/modal';
import H2Heading from '@shared/ui/h2-heading';
import OurProductCard from '@features/our-products-screen/ui/our-product-card';
import { IOurProductsScreenProps } from '@features/our-products-screen/model/types.ts';
import OurProductInfoCard from '@features/our-products-screen/ui/our-product-info-card';
import OurProductsModal from '@features/our-products-screen/ui/our-products-modal';
import {
  IOurDepartment,
  IOurInfoCard,
  IOurProducts,
} from '@features/our-products-screen/api/our-products-mock-data.tsx';

import LightSVG from '@shared/assets/gradients/light-svg.tsx';

import styles from './styles.module.css';

const OurProductsScreen = ({ h2Title, departments, mainTextColor, accentTextColor }: IOurProductsScreenProps) => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [currentProjects, setCurrentProjects] = useState<IOurProducts[]>([]);

  const openModalHandler = (projects: IOurProducts[]) => {
    setCurrentProjects(projects);
    setModalOpen(true);
  };
  const closeModalHandler = () => setModalOpen(false);

  return (
    <div className={styles.container}>
      <H2Heading heading={h2Title} mainTextColor={mainTextColor} accentTextColor={accentTextColor} />
      <div className={cn(styles.cardsContainer)}>
        <div className={styles.svgBGWrapper}>
          <LightSVG />
        </div>
        {departments?.map((item, index) => {
          if (item.type === 'department') {
            const currentItem = item as IOurDepartment;
            return (
              <OurProductCard
                key={index}
                variant={currentItem.variant}
                description={currentItem.title}
                icon={item.icon}
                onClick={() => openModalHandler(currentItem.projects ?? [])}
              />
            );
          }
          if (item.type === 'info') {
            const currentItem = item as IOurInfoCard;
            return (
              <OurProductInfoCard key={index} mainTitle={currentItem.mainTitle} extraTitle={currentItem.extraTitle} />
            );
          }
          return null;
        })}
      </div>
      <Modal isOpen={isModalOpen} onClose={closeModalHandler}>
        <OurProductsModal
          isOpen={isModalOpen}
          closeModalHandler={closeModalHandler}
          currentProjects={currentProjects}
        />
      </Modal>
    </div>
  );
};

export default OurProductsScreen;
