import { FC, useLayoutEffect, useRef } from 'react';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);

import styles from './elips.module.css';

interface ElipsProps {
  className?: string;
}

export const Elips: FC<ElipsProps> = ({ className = '' }) => {
  const svgRef = useRef<SVGSVGElement | null>(null);

  useLayoutEffect(() => {
    const ctx = gsap.context(() => {
      const svg = svgRef.current;
      if (!svg) return;

      const circles = svg.querySelectorAll('circle');
      const ellipses = svg.querySelectorAll('ellipse');

      gsap.set(ellipses, { scale: 0, opacity: 0 }); // свечение
      gsap.set(circles, { opacity: 0 }); // дуга

      const tl = gsap.timeline({
        scrollTrigger: {
          trigger: svg,
          start: 'top bottom',
        },
      });

      tl.to(
        circles,
        {
          duration: 1,
          opacity: 1,
          ease: 'power1.inOut',
          stagger: {
            from: 'start',
          },
        },
        '=1'
      );
      tl.to(
        ellipses,
        {
          scale: 1,
          opacity: 1,
          duration: 1,
          ease: 'power1.inOut',
          stagger: {
            each: 0.2,
            from: 'start',
          },
        },
        '-=0.1'
      );
    });
    return () => ctx.revert();
  }, []);

  return (
    <svg
      ref={svgRef}
      className={`${styles.container} ${className}`}
      width="100%"
      height="100%"
      viewBox="0 0 1754 1532"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_f_1201_9382)">
        <ellipse cx="877" cy="766.259" rx="277" ry="165.741" fill="#FF8A00" />
      </g>
      <g filter="url(#filter1_f_1201_9382)">
        <ellipse cx="877" cy="766.259" rx="240.582" ry="143.642" fill="#FF7A00" />
      </g>
      <g filter="url(#filter2_f_1201_9382)">
        <ellipse cx="877.001" cy="766.259" rx="205.267" ry="122.648" fill="#FB8027" />
      </g>
      <g filter="url(#filter3_f_1201_9382)">
        <ellipse cx="877" cy="635.324" rx="240.582" ry="61.3241" fill="#FB9A27" />
      </g>
      <g clipPath="url(#clip0_1201_9382)">
        <circle cx="876" cy="33" r="960" fill="#121212" />
        <circle cx="876" cy="33" r="960" fill="url(#paint0_radial_1201_9382)" fillOpacity="0.18" />
        <circle cx="876" cy="33" r="960" fill="url(#paint1_radial_1201_9382)" fillOpacity="0.25" />
        <circle cx="876" cy="33" r="960" fill="url(#paint2_radial_1201_9382)" fillOpacity="0.8" />
      </g>
      <defs>
        <filter
          id="filter0_f_1201_9382"
          x="0"
          y="0.518555"
          width="1754"
          height="1531.48"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="300" result="effect1_foregroundBlur_1201_9382" />
        </filter>
        <filter
          id="filter1_f_1201_9382"
          x="236.418"
          y="222.617"
          width="1281.16"
          height="1087.28"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="200" result="effect1_foregroundBlur_1201_9382" />
        </filter>
        <filter
          id="filter2_f_1201_9382"
          x="471.734"
          y="443.611"
          width="810.533"
          height="645.296"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="100" result="effect1_foregroundBlur_1201_9382" />
        </filter>
        <filter
          id="filter3_f_1201_9382"
          x="456.418"
          y="394"
          width="841.164"
          height="482.648"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="90" result="effect1_foregroundBlur_1201_9382" />
        </filter>
        <radialGradient
          id="paint0_radial_1201_9382"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(876.002 -927) rotate(90) scale(1920)"
        >
          <stop offset="0.85" stopOpacity="0" />
          <stop offset="0.918157" stopColor="#3A2100" stopOpacity="0.5" />
          <stop offset="1" stopColor="#EF9013" />
        </radialGradient>
        <radialGradient
          id="paint1_radial_1201_9382"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(874.091 -927) rotate(90) scale(1920)"
        >
          <stop offset="0.931679" stopOpacity="0" />
          <stop offset="0.965839" stopColor="#7A5C25" stopOpacity="0.5" />
          <stop offset="1" stopColor="#F3B84A" />
        </radialGradient>
        <radialGradient
          id="paint2_radial_1201_9382"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(874.091 -58.6103) rotate(90) scale(1051.61)"
        >
          <stop offset="0.992445" stopColor="#58411C" stopOpacity="0" />
          <stop offset="0.996945" stopColor="#7A5C25" stopOpacity="0.5" />
          <stop offset="1" stopColor="#F3B84A" />
        </radialGradient>
      </defs>
    </svg>
  );
};
