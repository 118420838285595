import { TimelineMobile } from '@shared/icons/timeline-mobile/timeline-mobile.tsx';
import { Timeline } from '@shared/icons/timeline/timeline.tsx';
import { PointForTimeline, PointForTimelineMobile } from '@entities/point-for-timeline';
import useIsMobile from '@shared/hooks/useIsMobile.tsx';

import { mockPoints } from '@entities/point-for-timeline/model/mock-point-for-timeline.ts';

const WhatIsSminexAnimation = () => {
  const { isMobile } = useIsMobile();

  return (
    <>
      {isMobile ? <TimelineMobile /> : <Timeline />}
      {isMobile ? <PointForTimelineMobile pointContent={mockPoints} /> : <PointForTimeline pointContent={mockPoints} />}
    </>
  );
};

export default WhatIsSminexAnimation;
