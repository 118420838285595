const LightSVG = () => {
  return (
    <svg width="100%" height="100%" viewBox="0 0 1440 1408" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_f_1780_3439)">
        <ellipse
          cx="229.485"
          cy="87.9687"
          rx="229.485"
          ry="87.9687"
          transform="matrix(0.965988 -0.258586 0.259052 0.965863 476.031 678.413)"
          fill="#F66700"
        />
      </g>
      <g filter="url(#filter1_f_1780_3439)">
        <ellipse
          cx="199.314"
          cy="76.2395"
          rx="199.314"
          ry="76.2395"
          transform="matrix(0.965988 -0.258586 0.259052 0.965863 508.215 681.94)"
          fill="#FF8A00"
        />
      </g>
      <g filter="url(#filter2_f_1780_3439)">
        <ellipse
          cx="178.611"
          cy="68.3713"
          rx="178.611"
          ry="68.3713"
          transform="matrix(0.965988 -0.258586 0.259052 0.965863 530.252 684.187)"
          fill="#FEC455"
        />
      </g>
      <defs>
        <filter
          id="filter0_f_1780_3439"
          x="-102.365"
          y="0.391602"
          width="1645.73"
          height="1407.29"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="300" result="effect1_foregroundBlur_1780_3439" />
        </filter>
        <filter
          id="filter1_f_1780_3439"
          x="126.939"
          y="214.147"
          width="1187.12"
          height="979.779"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="200" result="effect1_foregroundBlur_1780_3439" />
        </filter>
        <filter
          id="filter2_f_1780_3439"
          x="347.043"
          y="423.444"
          width="746.912"
          height="561.187"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="100" result="effect1_foregroundBlur_1780_3439" />
        </filter>
      </defs>
    </svg>
  );
};

export default LightSVG;
