import { useLayoutEffect, useRef } from 'react';
import useIsMobile from '@shared/hooks/useIsMobile.tsx';
import gsap from 'gsap';

const usePreloaderAnimation = (onTimelineEnd: () => void) => {
  const SminexRef = useRef(null);
  const DividerContainerRef = useRef(null);
  const DividerPart1Ref = useRef(null);
  const DividerPart2Ref = useRef(null);
  const bgRef = useRef(null);
  const TechRef = useRef(null);
  const containerRef = useRef(null);
  const logoWrapperRef = useRef(null);

  const { isMobile } = useIsMobile();

  useLayoutEffect(() => {
    const ctx = gsap?.context(() => {
      const tl = gsap.timeline();

      gsap.set(SminexRef.current, { willChange: 'filter, opacity, transform' });
      gsap.set(bgRef.current, { willChange: 'opacity' });

      tl.fromTo(
        SminexRef.current,
        { filter: 'blur(100px)', opacity: 0 },
        { filter: 'blur(0px)', opacity: 1, duration: 1.2, ease: 'slow' }
      )
        .fromTo(SminexRef.current, { x: '50%' }, { x: 0, duration: 1, ease: 'power4.out' })
        .fromTo(bgRef.current, { opacity: 0 }, { opacity: 1, duration: 2 })
        .fromTo(
          DividerPart1Ref.current,
          {
            y: 50,
            scale: 0.5,
            opacity: 0,
          },
          {
            y: 0,
            scale: 1,
            opacity: 1,
            duration: 1,
            ease: 'power4.out',
          },
          '-=2.5'
        )
        .fromTo(
          DividerPart2Ref.current,
          {
            y: 50,
            x: 10,
            scale: 0.5,
            opacity: 0,
          },
          {
            y: 0,
            x: 0,
            scale: 1,
            opacity: 1,
            duration: 1.5,
            ease: 'power4.out',
          },
          '-=2.5'
        )
        .fromTo(
          TechRef.current,
          {
            x: '-50%',
            opacity: 0,
          },
          {
            x: 0,
            opacity: 1,
            duration: 1,
            ease: 'power2.inOut',
          },
          '-=2'
        )
        .to(DividerContainerRef.current, { opacity: 0, duration: 0.2 }, '-=1')
        .to(DividerContainerRef.current, { opacity: 1, duration: 0.2 }, '-=0.8')
        .to(logoWrapperRef.current, { scale: isMobile ? 0.5 : 0.375, y: 0, duration: 0.5 })
        .fromTo(
          containerRef.current,
          {
            y: 0,
          },
          {
            y: '-100%',
            duration: 1,
            ease: 'power1.inOut',
          },
          '-=0.5'
        )
        .to(
          bgRef.current,
          {
            opacity: 0,
            scale: 2,
            duration: 0.25,
          },
          '-=1'
        )
        .to(
          containerRef.current,
          {
            opacity: 0,
            duration: 0.5,
          },
          '-=0.5'
        )
        .add(onTimelineEnd);
    });
    return () => ctx.revert();
  }, []);

  return {
    SminexRef,
    DividerContainerRef,
    DividerPart1Ref,
    DividerPart2Ref,
    bgRef,
    TechRef,
    containerRef,
    logoWrapperRef,
  };
};

export default usePreloaderAnimation;
