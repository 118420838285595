import { useState } from 'react';
import useHideScroll from '@shared/hooks/useHideScroll.tsx';
import usePreloaderAnimation from '@features/preloader-screen/lib/usePreloaderAnimation.tsx';

import SminexLogo from '@shared/assets/preloader/sminex-logo.tsx';
import DividerLogo from '@shared/assets/preloader/divider-logo.tsx';
import TechLogo from '@shared/assets/preloader/tech-logo.tsx';

import LightElipsPNG from '../assets/elips-fix.png';

import styles from './styles.module.css';
import cn from 'classnames';

const PreloaderScreen = () => {
  const [isShow, setShow] = useState(true);
  const {
    SminexRef,
    DividerContainerRef,
    DividerPart1Ref,
    DividerPart2Ref,
    bgRef,
    TechRef,
    containerRef,
    logoWrapperRef,
  } = usePreloaderAnimation(() => setShow(false));
  useHideScroll(isShow);

  if (!isShow) return null;

  return (
    <>
      <div className={styles.container} ref={containerRef}>
        <div className={cn(styles.logoWrapper, styles.relative)}>
          <div className={styles.logoWrapper} ref={logoWrapperRef}>
            <div className={styles.sminexLogo} ref={SminexRef}>
              <SminexLogo />
            </div>
            <div ref={DividerContainerRef} className={styles.dividerWrapper}>
              <DividerLogo ref1={DividerPart1Ref} ref2={DividerPart2Ref} />
            </div>
            <div ref={TechRef} className={styles.techLogo}>
              <TechLogo />
            </div>
          </div>
          <div className={styles.bgSVG} ref={bgRef}>
            <img src={LightElipsPNG} alt="light" />
          </div>
        </div>
      </div>
    </>
  );
};

export default PreloaderScreen;
