import React from 'react';
import styles from './styles.module.css';
import { SectionWhatIsSminexProps } from '@features/section-what-is-sminex/model/types';
import H2Heading from '@shared/ui/h2-heading';

const MAIN_TEXT_COLOR = '#A0A0A0';
const ACCENT_TEXT_COLOR = '#fff';

export const SectionWhatIsSminex: React.FC<React.PropsWithChildren<SectionWhatIsSminexProps>> = ({
  sectionContent,
  children,
  backgroundComponent: BackgroundComponent,
}) => {
  return (
    <div className={styles.sectionWrapper}>
      {BackgroundComponent && <BackgroundComponent className={styles.backgroundSvg} />}
      <div className={styles.aboutSminexWrapper}>
        <H2Heading
          heading={sectionContent.header}
          mainTextColor={MAIN_TEXT_COLOR}
          accentTextColor={ACCENT_TEXT_COLOR}
        />
        <div className={styles.description}>
          <div className={styles.text}>{sectionContent.firstDescription}</div>
          <div className={styles.text}>{sectionContent.secondDescription}</div>
        </div>
      </div>

      <div className={styles.svgWrapper}>{children}</div>
    </div>
  );
};
