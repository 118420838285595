import * as stylex from '@stylexjs/stylex';
import { styles } from './styles';
import { useEffect, useRef, useState } from 'react';
import { Modal } from '@shared/ui/modal';
import { VideoPlayer } from '@features/video-player';

interface VideoProps {
  previewUrl?: string;
  videoUrl?: string;
}

export const Video = ({ previewUrl, videoUrl }: VideoProps) => {
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.muted = true;
      videoRef.current.play().catch((error) => {
        console.log('Autoplay was prevented:', error);
      });
    }
  }, []);

  const handlePlay = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <button
      {...stylex.props(styles.videoContainer)}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        handlePlay();
      }}
    >
      <video
        ref={videoRef}
        src={previewUrl}
        autoPlay={true}
        controls={false}
        loop={true}
        playsInline
        {...stylex.props(styles.video)}
      />
      <Modal isOpen={isModalOpen} onClose={handleCloseModal}>
        {isModalOpen && (
          <div {...stylex.props(styles.iframe)}>
            <VideoPlayer url={videoUrl} />
          </div>
        )}
      </Modal>
    </button>
  );
};
