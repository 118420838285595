import { FC, useRef } from 'react';
import { StartScreen } from '@features/start-screen';
import { MarqueeLinesScreen } from '@features/marquee-line-screen';
import { ChooseUsScreen } from '@features/choose-us-screen';
import FeedbackScreen from '../../features/feedback-screen/ui';
import { Elips } from '@shared/icons/elips/elips.tsx';
import { SectionWhatIsSminex } from '@features/section-what-is-sminex';
import { WhatIsSminexAnimation } from '@features/what-is-sminex-animation';
import OurProductsScreen from '@features/our-products-screen/ui';
import { SectionOurTeam } from '@features/section-our-team';
import PreloaderScreen from '@features/preloader-screen/ui';

import styles from '@pages/mainPage/styles.module.css';

import {
  ACCENT_TEXT_COLOR,
  MAIN_TEXT,
  MAIN_TEXT_COLOR,
  SECONDARY_TEXT,
} from '@features/start-screen/lib/start-screen-mock-data.ts';
import { HEADING_TITLE, IT_DEPARTMENTS_OPTIONS } from '@features/feedback-screen/api/mock-data.ts';
import {
  OUR_PRODUCTS_DEPARTMENTS,
  OUR_PRODUCTS_TITLE,
} from '@features/our-products-screen/api/our-products-mock-data.tsx';
import firstScreenVideo from '@shared/assets/video/start-screen-video.mp4';
import { mockSections } from '@features/section-what-is-sminex/model/mock-what-is-sminex.ts';
import { mockPointsOurTeam } from '@features/section-our-team/model/mock-our-team';
import { LightAngle } from '@shared/icons/light-angle/light-angle';

export const MainPage: FC = () => {
  const feedbackRef = useRef<HTMLFormElement | null>(null);
  const executeScroll = () => {
    feedbackRef.current?.scrollIntoView({ behavior: 'smooth' }); // Прокрутка к секции
  };
  return (
    <div className={styles.container}>
      <LightAngle className={styles.backgroundSvg} />
      <PreloaderScreen />
      <StartScreen
        mainText={MAIN_TEXT}
        secondaryText={SECONDARY_TEXT}
        mainTextColor={MAIN_TEXT_COLOR}
        accentTextColor={ACCENT_TEXT_COLOR}
        disclaimerColor={MAIN_TEXT_COLOR}
        previewVideoUrl={firstScreenVideo}
        videoUrl={firstScreenVideo}
      />
      <SectionWhatIsSminex sectionContent={mockSections[0]}>
        <WhatIsSminexAnimation />
      </SectionWhatIsSminex>
      <SectionOurTeam
        title={mockPointsOurTeam.title}
        subtitle={mockPointsOurTeam.subtitle}
        pointOurTeamContent={mockPointsOurTeam.pointOurTeamContent}
        buttonText={mockPointsOurTeam.buttonText}
        onApplyClick={executeScroll}
      />
      <MarqueeLinesScreen />
      <ChooseUsScreen mainTextColor={MAIN_TEXT_COLOR} accentTextColor={ACCENT_TEXT_COLOR} />
      <SectionWhatIsSminex sectionContent={mockSections[1]} backgroundComponent={Elips} />
      <OurProductsScreen
        departments={OUR_PRODUCTS_DEPARTMENTS}
        h2Title={OUR_PRODUCTS_TITLE}
        mainTextColor={MAIN_TEXT_COLOR}
        accentTextColor={ACCENT_TEXT_COLOR}
      />
      <FeedbackScreen
        ref={feedbackRef}
        heading={HEADING_TITLE}
        dropdownOptions={IT_DEPARTMENTS_OPTIONS}
        mainTextColor={MAIN_TEXT_COLOR}
        accentTextColor={ACCENT_TEXT_COLOR}
      />
    </div>
  );
};
