import ReactDOM from 'react-dom';
import { ReactNode } from 'react';
import useHideScroll from '@shared/hooks/useHideScroll.tsx';

import * as stylex from '@stylexjs/stylex';
import { styles } from './styles';

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  children?: ReactNode;
  showCloseButton?: boolean;
}

export const Modal = ({ isOpen, children }: ModalProps) => {
  useHideScroll(isOpen);

  return ReactDOM.createPortal(
    <div {...stylex.props([styles.modalOverlay, isOpen ? styles.isOpen : styles.isClosed])}>
      <div {...stylex.props(styles.modalContent)}>{children}</div>
    </div>,
    document.body
  );
};
