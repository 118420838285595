import { FC, useLayoutEffect, useRef } from 'react';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { PointForTimelineProps } from '../../model/types';
import styles from './styles.module.css';

gsap.registerPlugin(ScrollTrigger);

export const PointForTimelineMobile: FC<PointForTimelineProps> = ({ pointContent }) => {
  const containerRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    const ctx = gsap.context(() => {
      if (containerRef.current) {
        const elements = containerRef.current.children;

        gsap.set(elements, { opacity: 0 });

        const tl = gsap.timeline({
          scrollTrigger: {
            trigger: containerRef.current,
            start: 'top bottom-=100',
            once: true,
          },
        });

        tl.add(() => {
          Array.from(elements).forEach((element, index) => {
            gsap.to(element, {
              opacity: 1,
              delay: index * 0.5,
              ease: 'power2.out',
            });
          });
        }, '+=7');
      }
    });
    return () => ctx.revert();
  }, [pointContent]);

  return (
    <div ref={containerRef} className={styles.pointForTimelineWrapperMobile}>
      {pointContent.map((point, index) => (
        <div key={index} className={`${styles.pointForTimelineMobile} ${styles.box}`}>
          <button className={styles.btnMobile}>{point.nameBtn}</button>
          <div className={styles.yearMobile}>{point.yearBtn}</div>
        </div>
      ))}
    </div>
  );
};
